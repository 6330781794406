import useAuthLogoutMutation from '@/mutations/useAuthLogoutMutation'
import routes from '@/routes'
import { useRouter } from 'next/router'
import { useToast } from '@packages/ui-components'
const useLogout = () => {
  const router = useRouter()
  const toast = useToast()
  const { mutateAsync } = useAuthLogoutMutation({})
  const onLogout = async () => {
    try {
      await mutateAsync(null)
      router.push(routes.login)
    } catch (e) {
      toast({
        title: 'Could Not Logout. Please Try Again',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }
  return onLogout
}
export default useLogout
