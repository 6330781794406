import { useMutation, UseMutationOptions } from 'react-query'

const handleResponse = async (response: Response) => {
  if (!response.ok) {
    const json = await response.json()
    // if there is an error on api-gateway it will return a message
    throw Error(json)
  }
  return response.json()
}

const useAuthLogoutMutation = (opts: UseMutationOptions<any, unknown, null, unknown>) => {
  return useMutation(
    (data) =>
      fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then(handleResponse),
    opts
  )
}

export default useAuthLogoutMutation
