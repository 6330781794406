import { Text } from '@packages/ui-components'
import getConfig from 'next/config'

export const AppVersion: React.FC = () => {
  const { publicRuntimeConfig } = getConfig()
  return (
    <>
      <Text fontFamily="thin">Version {publicRuntimeConfig?.version}</Text>
    </>
  )
}

export default AppVersion
