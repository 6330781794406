import React from 'react'

import { Box, Container, HStack, Spacer, Text, VStack, Flex } from '@packages/ui-components'
import AppVersion from '@/components/AdviserDashboard/AppVersion'

import LifetimeLogo from 'public/icons/Lifetime-logo.svg'
import PhoneCircle from 'public/icons/icon-phone-circle.svg'
import useLogout from '@/hooks/useLogout'

const currentYear = new Date().getFullYear()

const PageLayout = ({ children }: { children: any }) => {
  const onLogout = useLogout()
  return (
    <VStack align="stretch" flexGrow={1} spacing="10">
      <Box pt="60px" mb="6">
        <Container flex={1} display="flex" flexDirection="column" maxW="54rem" py={0} px="4">
          <HStack>
            <Box>
              <LifetimeLogo />
            </Box>
            <Spacer />
            <ContactUs />
          </HStack>
        </Container>
      </Box>
      <Box flexGrow={1}>
        <Container
          flex={1}
          display="flex"
          flexDirection="column"
          maxW="54rem"
          py={0}
          marginX="auto"
          px="2"
        >
          {children}
        </Container>
      </Box>
      <Box background="gray.50">
        <Container
          flex={1}
          display="flex"
          flexDirection="column"
          maxW="54rem"
          marginX="auto"
          marginY="14"
          py={0}
          px="4"
        >
          <Text fontFamily="thin" fontSize="sm" mb="60px">
            Personal information collected by Lifetime will be used to provide you with any service
            that you have requested, for our legitimate interests, or in accordance with your
            consent.
          </Text>
          <Flex justifyContent="space-between">
            <AppVersion />
            <Flex alignSelf="flex-end">
              <Text fontFamily="thin">&copy; {currentYear} Lifetime</Text>
              <Box
                as="button"
                fontFamily="thin"
                ml={4}
                onClick={onLogout}
                _hover={{ color: 'blue.500' }}
                _focus={{ color: 'blue.500' }}
              >
                Logout
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </VStack>
  )
}

const ContactUs = () => {
  return (
    <HStack>
      <PhoneCircle />
      <VStack spacing={-1} align="flex-start">
        <Text fontFamily="heading" color="blue.800">
          0800 656 466
        </Text>
        <Text fontFamily="thin" fontSize="sm" color="gray.230">
          Contact us
        </Text>
      </VStack>
    </HStack>
  )
}

export default PageLayout

export const AdviserLayout = ({ children }: { children: any }) => {
  const onLogout = useLogout()
  return (
    <VStack align="stretch" flexGrow={1} spacing="10">
      <Box pt={4} mb="6">
        <Container flex={1} display="flex" flexDirection="column" maxW="54rem" py={0} px="4">
          <HStack>
            <Box>
              <LifetimeLogo />
            </Box>
          </HStack>
        </Container>
      </Box>
      <Box flexGrow={1}>
        <Container
          flex={1}
          display="flex"
          flexDirection="column"
          maxW="54rem"
          py={0}
          marginX="auto"
          px="2"
        >
          {children}
        </Container>
      </Box>
      <Box background="gray.50">
        <Container
          flex={1}
          display="flex"
          flexDirection="column"
          maxW="54rem"
          marginX="auto"
          marginY="14"
          py={0}
          px="4"
        >
          <Flex justifyContent="space-between">
            <AppVersion />
            <Flex alignSelf="flex-end">
              <Text fontFamily="thin">&copy; {currentYear} Lifetime</Text>
              <Box
                as="button"
                fontFamily="thin"
                ml={4}
                onClick={onLogout}
                _hover={{ color: 'blue.500' }}
                _focus={{ color: 'blue.500' }}
              >
                Logout
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </VStack>
  )
}
