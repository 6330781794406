const routes = {
  index: '/',

  getStarted: '/get-started',
  questions: 'questions',
  questionsGetStarted: '/adviser/client/[clientAuthId]/getStarted',
  questionsOnBehalfOf: '/adviser/client/[clientAuthId]/questions',

  completed: '/completed',
  adviserCompleted: '/adviser/client/[clientAuthId]/completed',
  disabled: '/disabled',
  callback: '/callback',
  login: '/login',
  loginPending: '/login/pending',
  logout: '/logout',

  adviserSearch: '/adviser',
  adviserDashboard: '/adviser-dashboard/[clientId]',
  howCanWeHelpYou: '/adviser/how-can-we-help-you/[clientId]',
  properties: '/adviser/properties/[clientId]',
  kiwisaver: '/adviser/your-kiwisaver/[clientId]',
  financialProtection: '/adviser/financial-protection/[clientId]',
  progress: '/adviser/progress/[clientId]',
  policySchedule: '/adviser/policy-schedule/[clientId]',
  financialProtectionV2: '/adviser/financial-protection-v2/[clientId]',
  // Tools
  goalModelling: '/adviser/goal-modelling/[clientId]',
} as const

export default routes

export type Routes = typeof routes
export type Route = Routes[keyof Routes]

export const getPageFromUrl = (url: string) => {
  const page = Object.keys(routes).find((key) => routes[key as keyof typeof routes] === url)
  if (page) {
    return page.charAt(0).toUpperCase() + page.slice(1)
  }
  return 'Unknown'
}
