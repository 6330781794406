import { Flex, FlexProps } from '@packages/ui-components'
import Head from 'next/head'
interface PageContainer extends FlexProps {
  title: string
  children: React.ReactNode
  collapse?: boolean
  headerRight?: React.ReactNode
}
export default function PageContainer({ title, children, ...props }: PageContainer) {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={title} key="title" />
      </Head>

      <Flex flexDirection="column" flex={1} {...props}>
        {children}
      </Flex>
    </>
  )
}
